import Vue from 'vue'
import VueRouter from 'vue-router'
import WatchersStat from "@/reports/WatchersStat";
import LoginScreen from "@/views/LoginScreen";
import {URLSearchParams} from "core-js/modules/web.url-search-params";
import PilotsStat from "@/reports/PilotsStat";
import ReportsMain from "@/views/ReportsMain";
import MainMenuScreen from "@/views/MainMenuScreen";

Vue.use(VueRouter)

// "id": 1606908470,
//     "first_name": "Михаил",
//     "last_name": "(Markes)",
//     "username": "M_A_Markes",
//     "photo_url": "https://t.me/i/userpic/320/E2pDmguTYql4QgAoI4ure9CoPfWXm1jjc2DLMQrVDdU.jpg",
//     "auth_date": 1628960039,
//     "hash": "7db8fb07408870f74cac6325305d35f23eef5c2e1de9570447e7653bdb83e479"


function getToken() {
  let token = null;
  if (window.location.search) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let authObj = (({id, first_name, last_name, username, photo_url, auth_date, hash}) =>
        ({
          id,
          first_name,
          last_name,
          username,
          photo_url,
          auth_date,
          hash
        }))(Object.fromEntries(urlSearchParams.entries()));

    ['first_name', 'last_name', 'photo_url'].forEach(
        (field)=> {
          if (authObj[field] === undefined) delete authObj[field];
        }
    )

    if (['id', 'username', 'auth_date', 'hash'].some((field)=>authObj[field] === undefined))
        authObj = null;

    if (authObj) {
      token = String(new URLSearchParams(authObj));
      localStorage.setItem('apollo-token', token);
    }
  }

  token = localStorage.getItem('apollo-token');

  return token;
}

const ifAuthenticated = (to, from, next)=>{
  let token = getToken();
  if (token) {
    next();
  } else {
    // next('/auth');
  }
};

const ifNotAuthenticated = (to, from, next)=>{
  let token = getToken();
  if (!token) {
    next();
  } else {
    next('/');
  }
};


const routes = [
  {
    path: '/',
    name: 'Главное',
    component: MainMenuScreen,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/reports',
    name: 'Отчёты',
    component: ReportsMain,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/reports/watchers',
    name: 'Статистика Смотрящих',
    component: WatchersStat,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/reports/pilots',
    name: 'Статистика Пилотов',
    component: PilotsStat,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/auth',
    name: 'Аутентификация',
    component: LoginScreen,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/seniors',
    name: 'Старшие',
    beforeEnter(to, from, next){
      let token = getToken();
      if (token) {
        location.href = '/seniors';
      } else {
        next('/auth');
      }
    }

  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
