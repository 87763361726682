<template>
<image-button
    icon="@assets/icon-logout.svg"
    class="logout"
    @click="logout"
    title="Выйти из учётной записи"
>

</image-button>
</template>

<script>
import {onLogout} from "@/vue-apollo";
import ImageButton from "@/components/controls/ImageButton";

export default {
  name: "LogoutButton",
  components: {ImageButton},
  methods: {
    logout() {
      onLogout(this.$apollo.getClient());
      window.location.reload()
    }
  }

}
</script>

<style scoped>
.logout{
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  z-index: 1;
  background-color: rgba(255,255,255,0.5);
}
</style>