<template>
  <div class="loader-bkg">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: "WaitIndicator"
}
</script>

<style scoped>
  .loader-bkg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    z-index: 50;
  }

  .loader {
    top: 50%;
    left: 50%;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    z-index: 100;
    margin-top: -16px;
    margin-left: -16px;
    font-size: 5px;
    position: absolute;
    text-indent: -9999em;
    border: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #ffffff;
    transform: translateZ(0);
    animation: loading-icon 1.1s infinite linear;
  }

  .loader:after {
    width: 10em;
    height: 10em;
    border-radius: 50%;
  }

  @keyframes loading-icon {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

</style>