import { render, staticRenderFns } from "./PilotsStat.vue?vue&type=template&id=52f3b236&scoped=true&"
import script from "./PilotsStat.vue?vue&type=script&lang=js&"
export * from "./PilotsStat.vue?vue&type=script&lang=js&"
import style0 from "./PilotsStat.vue?vue&type=style&index=0&id=52f3b236&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52f3b236",
  null
  
)

export default component.exports