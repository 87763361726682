<template>
  <div v-if="roles" class="route-box">
<!--    <router-link to="/" class="app-tile disabled" v-if="roles.includes('Ст.ГПА')">-->
<!--      <img src="@/assets/badge-seniors.png"/>-->
<!--    </router-link>-->
    <router-link to="/seniors" class="app-tile" v-if="roles.includes('Ст.ГПА')">
      <img src="@/assets/badge-seniors.png"/>
    </router-link>
    <router-link to="/" class="app-tile disabled" v-if="roles.includes('ОД БПЛА')">
      <img src="@/assets/badge-od.png"/>
    </router-link>
    <router-link to="/" class="app-tile disabled" v-if="roles.includes('АвиаКоорд')">
      <img src="@/assets/badge-ak.svg"/>
    </router-link>
    <router-link  to="/" class="app-tile disabled" v-if="roles.includes('Смотрящий')">
      <img src="@/assets/badge-watchers.png"/>
    </router-link>
    <router-link to="/reports" class="app-tile" v-if="roles.includes('Ст.ГПА')">
      <img src="@/assets/badge-stat.svg"/>
    </router-link>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "MainMenuScreen",
  methods: {
    test() {
      location.href = 'http://localhost:8081/';
    }
  },
  apollo: {
    roles: {
      query: gql`query {
    getMyRoles {
        success
        strings
        errors
    }
}`,
      update: (data)=>data.getMyRoles.strings
    }
  }
}
</script>

<style scoped>
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.app-tile {
  display: block;
  width: 200px;
  height: 120px;
  border: 1mm solid #804040;
  border-radius: 15px;
  vertical-align: center;
  box-shadow: 10px 10px 5px rgba(0,0,0,0.2);
}

.app-tile:active:not(.disabled) {
  box-shadow: 3px 3px 5px rgba(0,0,0,0.2);
  transform: translate(7px, 7px);
}
.app-tile img {
  position: relative;
  height: 80%;
  top:50%;
  transform: translateY(-50%);
}

.disabled img {
  filter: saturate(10%);
}

.route-box {
  position: absolute;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-flow: row wrap;
  gap: 40px 40px;
  width: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

</style>