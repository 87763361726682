<template>
  <div>
    <h1>Статистика по пилотам</h1>
    <WaitIndicator v-if="$apollo.loading"></WaitIndicator>
    <period-picker @datePicked="onPeriodSelected"></period-picker>

    Результат:<p></p>
    <table v-if="stat">
      <tr>
        <th></th>
        <th colspan="3" style="padding-right: 10px;">В системе просмотра</th>
        <th colspan="3" style="padding-left: 10px;">Обработка заявок</th>
      </tr>
      <tr>
        <th>Пилот</th>
        <th>Снимков</th>
        <th>Поисков</th>
        <th style="padding-right: 10px;">Дней</th>
        <th style="padding-left: 10px;">Закрытий</th>
        <th>Отчётов о начале</th>
        <th>Отчётов о завершении</th>
      </tr>
      <tr v-for="pStat, idx in stat" :key="pStat.pilotId" :class="{'odd-row': 1-idx%2}">
        <td>{{pStat.pilotId}}</td>
        <td>{{pStat.imagesLoaded}}</td>
        <td>{{pStat.searches}}</td>
        <td>{{pStat.days}}</td>

        <td>{{pStat.closeCount}}</td>
        <td>{{pStat.startCount}}</td>
        <td>{{pStat.stopCount}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import WaitIndicator from "@/components/controls/WaitIndicator";
import gql from "graphql-tag";
import PeriodPicker from "@/reports/PeriodPicker";


export default {
  name: "PilotsStat",
  components: {
    PeriodPicker,
    WaitIndicator,
  }
  ,
  computed: {
    userIds(){
      return this.stat? null && Object.keys(this.stat).map((u)=>parseInt(u)) : null;
    },
  },
  data: () => {
    return {
      date_query: {},
    }
  },
  methods: {
    onPeriodSelected(date_query){
      this.date_query = date_query;
    },
    getUser(user) {

      if(!this.users || !this.users[user])
        return null;
      return this.users[user];
    }

  },
  apollo:{
    stat: {
      query: gql`query ($date_from: Date! $date_to: Date!){
        getPilotStatForPeriod(dateFrom: $date_from dateTo: $date_to){
            success
            errors
            stat {
                pilotId
                imagesLoaded
                searches
                days

                closeCount
                startCount
                stopCount
            }
        }

    }`,
      skip(){ return !this.date_query.start},
      update: data => data.getPilotStatForPeriod.stat,
      variables() {
        return {
          date_from: this.date_query.start,
          date_to: this.date_query.end
        }
      }
    },
    users: {
      query: gql`query ($ids: [Int!]!){
        getUserData(uIds: $ids){
            success
            errors
            users {
                id
                username
                first_name
                last_name
            }
        }

    }`,
      skip() { return !this.userIds},
      update: data => data.getUserData.users.reduce((a,b)=> {
        a[b.id] = b; return a;
      }, {}),
      variables() {
        return {
          ids: this.userIds,
        }
      }
    },
  }
}
</script>

<style scoped>
  body {
    font-family: verdana;
    font-size: 12px;
    color: #767676;
    margin-left: 30px;
  }

  th {
    text-orientation: sideways;

  }

  h1 {
    color: #000;
    /*margin-bottom: -1px;*/
  }

  h2 {
    color: #000;
    margin-bottom: -1px;
  }

  h3 {
    color: #000;
    margin-bottom: -1px;
  }

  a {
    color: #000;
  }

  .rotated-header {
    border-spacing: 0;
  }

  th{
    font-weight: normal;
  }

  table{
    border-spacing: 0;
  }

  .rotated-header th:not(:first-child) {
    height: 240px;
    vertical-align: bottom;
    text-align: left;
    line-height: 2;
    font-weight: normal;
    /*padding: 0;*/
  }

  .rotated-header-container {
    width: 50px;
  }

  .rotated-header-content {
    width: 250px;
    transform-origin: bottom left;
    transform: translate(50px) rotate(-45deg);
    border-bottom: #2c3e50 0.1mm solid;
  }

  .with-border {
    border-right: #2c3e50 0.1mm solid;
  }

  .odd-row {
    background-color: lightgray;
  }

  .total-column {
    font-weight: bold;
  }

  .total-row {
    height: 40px;
    vertical-align: center;
    font-weight: bold;
  }

</style>