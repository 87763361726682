<template>
<div>
  <div v-if="$route.path === '/reports' && roles" class="route-box">
    <router-link to="/reports/watchers" class="app-tile" v-if="roles.includes('Ст.ГПА')">

      <p><img src="@/assets/icon-watched-on.svg"/>
        <div class="title">Просмотры по поискам по смотрящим</div>
    </router-link>
    <router-link to="/reports/pilots" class="app-tile" v-if="roles.includes('Ст.ГПА')">

      <p><img src="@/assets/drone.svg"/>
        <div class="title">Статистика по пилотам</div>
    </router-link>
  </div>
</div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "ReportsMain",
  apollo: {
    roles: {
      query: gql`query {
    getMyRoles {
        success
        strings
        errors
    }
}`,
      update: (data)=>data.getMyRoles.strings
    }
  }
}
</script>

<style scoped>

.app-tile {
  display: block;
  width: 200px;
  height: 120px;
  border: 1mm solid #804040;
  border-radius: 15px;
  /*vertical-align: center;*/
  box-shadow: 10px 10px 5px rgba(0,0,0,0.2);
}

a:link, a:visited, a:hover, a:active {
  color: black;
  font-weight: bold;
  text-decoration: none;
  /*font-size: smaller;*/
  margin-right: 0;
}

.app-tile:active {
  box-shadow: 3px 3px 5px rgba(0,0,0,0.2);
  transform: translate(7px, 7px);
}
.app-tile img {
  float: left;
  max-height: 80%;
  max-width: 20%;
  width: auto;
  height: auto;
  margin: 7px 7px 7px 0;
  padding: 5px;
  /*transform: translateY(-50%);*/
}

.title {
  margin-right: 0;
}

.route-box {
  position: absolute;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-flow: row wrap;
  gap: 40px 40px;
  width: 80%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

</style>