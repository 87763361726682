<template>
  <div class="image-button" :class="{'image-button-enabled': !disabled}" @click="buttonClick">

    <img :src="relIcon" class="image-button-image" v-if="icon"/>
    <div class="image-button-text" v-if="text">
      {{text}}
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageButton",
  props: ["text", "icon", "disabled"],
  emits: ['click'],
  computed:{
    relIcon: function() {
      if(this.icon[0] != '@')
        return this.icon;

      return require(`@/${this.icon.substr(1)}`);
    }
  },
  methods: {
    buttonClick(){
      if(!this.disabled)
        this.$emit('click', this.$attrs.ref);
    }
  }
}
</script>

<style scoped>

.image-button {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}

.image-button-image {
  /*position: absolute;*/
  /*top: 0;*/
  /*left: 0;*/
  width: auto;
  height: auto;
  margin: 5px;
  max-height: 100%;
}

.image-button-enabled:active {
  transform: translate3d(1px, 1px, 0 );
  transition-duration: 100ms;
}

.image-button-text{
  /*position: absolute;*/
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  top: 0;
  left: 0;
  /*width: 100%;*/
  height: 100%;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}


</style>