<template>
  <div id="app">
    <div width="100%" style="text-align: right">
      <logout-button class="logout" v-if="$route.path === '/'"></logout-button>
    </div>
    <keep-alive include="Searches">
      <router-view/>
    </keep-alive>
  </div>
</template>

<script>
import LogoutButton from "@/components/controls/LogoutButton";

export default {
  components: {LogoutButton},
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


.logout{
  /*position: absolute;*/
  /*display: flex;*/
  /*text-align: right;*/
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  z-index: 1;
  margin-right: 0;
}

</style>
