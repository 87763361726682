<template>
  <VueCtkDateTimePicker
      v-model="date_range"
      :label="date_options.label"
      :only-date="date_options.onlyDate"
      :range="date_options.range"
      :first-day-of-week="date_options.firstDayOfWeek"
      :format="date_options.format"
      :custom-shortcuts="date_options.customShortcuts"
      @validate="dpValidate"
      @is-hidden="dpHideHandler"
  ></VueCtkDateTimePicker>
</template>

<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

export default {
  name: "PeriodPicker",
  components: {VueCtkDateTimePicker},
  emits: ['datePicked'],
  data: () => {
    return {
      date_range: null,
      date_query: {},
      date_options: {
        label: "Выберите начало и конец периода",
        onlyDate: true,
        range: true,
        firstDayOfWeek: 1,
        format: 'YYYY-MM-DDTHH:mm:ss',
        customShortcuts: [
          { key: 'thisWeek', label: 'Эта неделя', value: 'isoWeek' },
          { key: 'lastWeek', label: 'Прошлая неделя', value: '-isoWeek' },
          // { key: 'last7Days', label: 'Last 7 days', value: 7 },
          { key: 'last30Days', label: 'Последние 30 дней', value: 30 },
          { key: 'thisMonth', label: 'Этот месяц', value: 'month' },
          { key: 'lastMonth', label: 'Прошлый месяц', value: '-month' },
          // { key: 'thisYear', label: 'This year', value: 'year' },
          // { key: 'lastYear', label: 'Last year', value: '-year' }
        ]
      }
    }
  },
  methods: {
    dpValidate: function(){
      if (!this.date_range.end && this.date_range.start){
        const now = new Date();
        this.date_range.end = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
            23, 59, 59, 999).toISOString().substr(0, 19);
      }
      this.date_query = this.date_range;
      this.$emit('datePicked', this.date_query);
    },
    dpHideHandler: function (){
      this.date_range = this.date_query;
    },
  },
}
</script>

<style scoped>

</style>