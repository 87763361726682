<template>
  <div class="auth-screen">
    <h1>Вы не авторизованы в системе</h1>
    <h2>Для входа в систему используйте ссылку, полученную от бота выдачи задач</h2>
<!--    <h2>Инструкции будут в канале "Оповещение"</h2>-->
<!--    <vue-telegram-login-->
<!--        mode="callback"-->
<!--        telegram-login="LA_UAV_Manager_bot"-->
<!--        @callback="onTelegramAuth"-->
<!--    />-->

<!--    <l-a-telegram-login @login="onTelegramAuth"></l-a-telegram-login>-->
<!--    <p><button @click="thisIsLogin">Типа, логин</button></p>-->
<!--    <p><button @click="thisIsLogout">Типа, логаут</button></p>-->
  </div>
</template>

<script>
import {
  onLogin,
  // onLogout
} from "@/vue-apollo";
// import {vueTelegramLogin} from "vue-telegram-login"
// import LATelegramLogin from "@/components/controls/LATelegramLogin";

export default {
  name: "LoginScreen",
  // components: {LATelegramLogin,
    // vueTelegramLogin
  // },
  methods: {
    // thisIsLogin: function (){
    //   const authData = {
    //     "id": 1606908470,
    //     "first_name": "Михаил",
    //     "last_name": "(Markes)",
    //     "username": "M_A_Markes",
    //     "photo_url": "https://t.me/i/userpic/320/E2pDmguTYql4QgAoI4ure9CoPfWXm1jjc2DLMQrVDdU.jpg",
    //     "auth_date": 1628960039,
    //     "hash": "7db8fb07408870f74cac6325305d35f23eef5c2e1de9570447e7653bdb83e479"
    //   };
    //   const token = String(new URLSearchParams(authData));
    //   onLogin(this.$apollo.getClient(), token);
    //   if( window.history.length > 2 )
    //     this.$router.back(-1);
    //   else
    //     this.$router.push('/');
    // },
    // thisIsLogout: function(){
    //   onLogout(this.$apollo.getClient());
    // },
    onTelegramAuth: function(user) {
      alert('Logged in as ' + user.first_name + ' ' + user.last_name + ' (' + user.id + (user.username ? ', @' + user.username : '') + ')');
      const token = String(new URLSearchParams(user));
      onLogin(this.$apollo.getClient(), token);
      if( window.history.length > 2 )
        this.$router.back(-1);
      else
        this.$router.push('/');
    }
  },
  apollo:{
  }
}
</script>

<style scoped>
.auth-screen {
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  background-image: url("../assets/pics/barrier.jpg");
  /*background-clip: padding-box;*/
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  color: white;
}
</style>