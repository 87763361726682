import Vue from 'vue'
import App from './App.vue'
import { createProvider } from './vue-apollo'
import router from './router'

Vue.config.productionTip = false
const AUTH_TOKEN = 'apollo-token';

window.getToken = () => localStorage.getItem(AUTH_TOKEN);

new Vue({
  apolloProvider: createProvider(),
  router,
  render: h => h(App)
}).$mount('#app')
