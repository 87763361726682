<template>
  <div>
    <h1>Статистика просмотров по смотрящим</h1>
    <WaitIndicator v-if="$apollo.loading"></WaitIndicator>
    <div v-if="$apollo.queries.users.loading && !$apollo.queries.searches.loading && !$apollo.queries.stat.loading" class="text-over">
      <h3>Запрашиваем имена пользователей из телеграма. Это займёт какое-то время. Терпение!</h3>
    </div>
    <period-picker @datePicked="onPeriodSelected"></period-picker>

    Результат:
    <table v-if="stat" class="rotated-header">
      <tr>

        <th style="width: 1px;"></th>
        <th></th>
        <th></th>
        <th>
          <div class="rotated-header-container">
          <div class="rotated-header-content">
            <b>Всего</b>
          </div>
        </div>
        </th>
        <th v-for="_, search in stat[Object.keys(stat)[0]]" :key="search" >

          <div class="rotated-header-container">
            <div class="rotated-header-content">
              {{search}} {{getSearchLastName(search)}}
            </div>
          </div>
        </th>
      </tr>
      <tr class="total-row">
        <td colspan="3" style="padding-right: 20px; text-align: right">Всего</td>
        <td class="with-border total-column">{{grandTotal}}</td>
        <td v-for="total, search in totalsBySearches" :key="search" class="with-border">
          {{ total }}
        </td>
      </tr>
      <tr v-for="[user, total], idx in totalsByUsers" :key="user" :class="{'odd-row': 1-idx%2}">
        <td>{{idx+1}}</td>
        <td style="text-align: right"><span v-if="getUser(user)">@{{ getUser(user).username }}</span><span v-else>{{ user }}</span></td>
          <td>
            <span v-if="users">
              <span v-if="getUser(user)">
  <!--            <a :href="'https://t.me/'+getUser(user).username">{{ getUser(user).username }}</a>-->
              <span v-if="getUser(user).first_name">{{getUser(user).first_name}} </span>
              <span v-if="getUser(user).last_name">{{getUser(user).last_name}}</span>
              </span>
              <span v-else><i>Неизвестный</i></span>
          </span>
            <span v-else>...</span>
          </td>
          <td class="with-border total-column">{{total}}</td>
          <td v-for="val, srch in stat[user]" :key="srch" class="with-border"><span v-if="val">{{val}}</span></td>
      </tr>
    </table>
  </div>
</template>

<script>
import WaitIndicator from "@/components/controls/WaitIndicator";
import gql from "graphql-tag";
import PeriodPicker from "@/reports/PeriodPicker";


export default {
  name: "WatchersStat",
  components: {
    PeriodPicker,
    WaitIndicator,
  }
  ,
  computed: {
    searchIds() {
       return (this.stat && Object.keys(this.stat).length && this.stat[Object.keys(this.stat)[0]] &&
          Object.keys(this.stat[Object.keys(this.stat)[0]])) || null;
    },
    userIds(){
      return this.stat? Object.keys(this.stat).map((u)=>parseInt(u)) : null;
    },
    totalsBySearches(){
      const totals = {}
      for(const [, uData] of Object.entries(this.stat)) {
        // console.log(uData, typeof uData, Object.entries(uData));
        for (const search in uData) {
          // console.log(search, uData[search]);
          totals[search] = totals[search] ? totals[search] + parseInt(uData[search]) : parseInt(uData[search]);
        }
      }
      return totals;
    },
    totalsByUsers(){
      const totals = {}
      for(const [user, uData] of Object.entries(this.stat)) {
        totals[user] = 0;
        for (const search in uData)
          totals[user] += parseInt(uData[search]);
      }
      return (Object.entries(totals).sort(([,a], [,b])=>b-a));
    },
    grandTotal(){
      return Object.values(this.totalsBySearches).reduce((a,b)=>a+b, 0);
    }
  },
  data: () => {
    return {
      date_query: {},
    }
  },
  methods: {
    onPeriodSelected(date_query){
      this.date_query = date_query;
    },
    getSearchLastName(search) {
      if(!this.searches)
        return '...';
      if(!this.searches[search])
        return '';
      return this.searches[search].FIO.replace(/\s.*/, '');
    },
    getUser(user) {
      if(!this.users || !this.users[user])
        return null;
      return this.users[user];
    }

  },
  apollo:{
    stat: {
      query: gql`query WatchStat($date_from: Date! $date_to: Date!){
        getWatchersStatForPeriod(dateFrom: $date_from dateTo: $date_to){
            success
            errors
            stat
        }

    }`,
      skip(){ return !this.date_query.start},
      update: data => data.getWatchersStatForPeriod.stat,
      variables() {
        return {
          date_from: this.date_query.start,
          date_to: this.date_query.end
        }
      }
    },
    searches: {
      query: gql`query ($ids: [String]!){
        listImageCollections(imageCollectionId: $ids){
            success
            errors
            images {
                SearchID
                FIO
            }
        }

    }`,
      skip() { return !this.searchIds},
      update: data => data.listImageCollections.images.reduce((a, b) => {a[b.SearchID] = b;return a;}, {}),
      variables() {
        return {
          ids: this.searchIds,
        }
      }
    },
    users: {
      query: gql`query ($ids: [String!]!){
        getUserData(uIds: $ids){
            success
            errors
            users {
                id
                username
                first_name
                last_name
            }
        }

    }`,
      skip() { return !this.userIds},
      update: data => data.getUserData.users && data.getUserData.users.reduce((a,b)=> {
        a[b.id] = b; return a;
      }, {}),
      variables() {
        return {
        ids: this.userIds.map(x=>String(x)),
        }
      }
    },
  }
}
</script>

<style scoped>
  body {
    font-family: verdana;
    font-size: 12px;
    color: #767676;
    margin-left: 30px;
  }

  th {
    text-orientation: sideways;

  }

  h1 {
    color: #000;
    /*margin-bottom: -1px;*/
  }

  h2 {
    color: #000;
    margin-bottom: -1px;
  }

  h3 {
    color: #000;
    margin-bottom: -1px;
  }

  a {
    color: #000;
  }

  .rotated-header {
    border-spacing: 0;
  }

  .rotated-header th:not(:first-child) {
    height: 240px;
    vertical-align: bottom;
    text-align: left;
    line-height: 2;
    font-weight: normal;
    padding: 0;
  }

  .rotated-header-container {
    width: 50px;
  }

  .rotated-header-content {
    width: 250px;
    transform-origin: bottom left;
    transform: translate(50px) rotate(-45deg);
    border-bottom: #2c3e50 0.1mm solid;
  }

  .with-border {
    border-right: #2c3e50 0.1mm solid;
  }

  .odd-row {
    background-color: lightgray;
  }

  .total-column {
    font-weight: bold;
  }

  .total-row {
    height: 40px;
    vertical-align: center;
    font-weight: bold;
  }

  .text-over {
    color: white;
    z-index: 51;
    display: block;
    position: absolute;
    width: 100%;
    top: 55%;
    text-align: center;
  }

  .text-over h3 {
    color: white;
    font-weight: normal;
  }
</style>
